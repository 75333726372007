exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bike-tours-and-rentals-tsx": () => import("./../../../src/pages/bike-tours-and-rentals.tsx" /* webpackChunkName: "component---src-pages-bike-tours-and-rentals-tsx" */),
  "component---src-pages-community-tours-tsx": () => import("./../../../src/pages/community-tours.tsx" /* webpackChunkName: "component---src-pages-community-tours-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-heritage-walks-tsx": () => import("./../../../src/pages/heritage-walks.tsx" /* webpackChunkName: "component---src-pages-heritage-walks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

